
const actionTypePrefix = 'slot-booking/';
import { ISlotBooking } from "@common/domain/models/SlotBookingModel";

export const SlotBookingActions = {
    FETCH_SLOT_BOOKING: `${actionTypePrefix}FETCH_SLOT_BOOKING`,
    FETCH_SLOT_BOOKING_SUCCESS: `${actionTypePrefix}FETCH_SLOT_BOOKING_SUCCESS`,
    FETCH_SLOT_BOOKING_FAILURE: `${actionTypePrefix}FETCH_SLOT_BOOKING_FAILURE`,

    CREATE_SLOT_BOOKING: `${actionTypePrefix}CREATE_SLOT_BOOKING`,
    CREATE_SLOT_BOOKING_SUCCESS: `${actionTypePrefix}CREATE_SLOT_BOOKING_SUCCESS`,
    CREATE_SLOT_BOOKING_FAILURE: `${actionTypePrefix}CREATE_SLOT_BOOKING_FAILURE`,

    UPDATE_SLOT_BOOKING: `${actionTypePrefix}UPDATE_SLOT_BOOKING`,
    UPDATE_SLOT_BOOKING_SUCCESS: `${actionTypePrefix}UPDATE_SLOT_BOOKING_SUCCESS`,
    UPDATE_SLOT_BOOKING_FAILURE: `${actionTypePrefix}UPDATE_SLOT_BOOKING_FAILURE`,

    FETCH_AVAILABLE_SLOTS: `${actionTypePrefix}FETCH_AVAILABLE_SLOTS`,
    FETCH_AVAILABLE_SLOTS_SUCCESS: `${actionTypePrefix}FETCH_AVAILABLE_SLOTS_SUCCESS`,
    FETCH_AVAILABLE_SLOTS_FAILURE: `${actionTypePrefix}FETCH_AVAILABLE_SLOTS_FAILURE`,
};

export interface ICreateSlotBookingPayload {
    slotBooking: ISlotBooking;
    userId: string;
}

export interface IUpdateSlotBookingPayload {
    slotBooking: ISlotBooking;
    userId: string;
}

export interface IGetSlotBookingPayload {
    userId: string;
}

export interface IGetSlotBookingAction {
    type: string;
    payload: IGetSlotBookingPayload;
}

export interface ICreateSlotBookingAction {
    type: string;
    payload: ICreateSlotBookingPayload;
}

export interface IUpdateSlotBookingAction {
    type: string;
    payload: IUpdateSlotBookingPayload;
}

export const getSlotBooking = (payload: IGetSlotBookingPayload): IGetSlotBookingAction => ({
    type: SlotBookingActions.FETCH_SLOT_BOOKING,
    payload
});

export const getSlotBookingSuccess = (payload: ISlotBooking) => {
    return{
    type: SlotBookingActions.FETCH_SLOT_BOOKING_SUCCESS,
    payload
}};

export const getSlotBookingFailure = (error: string) => ({
    type: SlotBookingActions.FETCH_SLOT_BOOKING_FAILURE,
    payload: error,
});

export const createSlotBooking = (payload: ICreateSlotBookingPayload): ICreateSlotBookingAction => {
    return {
    type: SlotBookingActions.CREATE_SLOT_BOOKING,
    payload
}};

export const createSlotBookingSuccess = (payload: ISlotBooking) => ({
    type: SlotBookingActions.CREATE_SLOT_BOOKING_SUCCESS,
    payload
});

export const createSlotBookingFailure = (error: string) => ({
    type: SlotBookingActions.CREATE_SLOT_BOOKING_FAILURE,
    payload: error,
});

export const updateSlotBooking = (payload: IUpdateSlotBookingPayload): IUpdateSlotBookingAction => ({
    type: SlotBookingActions.UPDATE_SLOT_BOOKING,
    payload
});

export const updateSlotBookingSuccess = (payload: ISlotBooking) => ({
    type: SlotBookingActions.UPDATE_SLOT_BOOKING_SUCCESS,
    payload
});

export const updateSlotBookingFailure = (error: string) => ({
    type: SlotBookingActions.UPDATE_SLOT_BOOKING_FAILURE,
    payload: error,
});

export const getAvailableSlots = (payload: any): any => {
    return {
        type: SlotBookingActions.FETCH_AVAILABLE_SLOTS,
        payload
    };
}

export const getAvailableSlotsSuccess = (payload: any) => ({
    type: SlotBookingActions.FETCH_AVAILABLE_SLOTS_SUCCESS,
    payload
});

export const getAvailableSlotsFailure = (error: string) => ({
    type: SlotBookingActions.FETCH_AVAILABLE_SLOTS_FAILURE,
    payload: error,
});
