import { JsonProperty } from "json-object-mapper";

export interface IAssessmentFeedback {
    status: string;
    message: string;
    feedbacks: any;
}

export interface ISentence {
    name: string;
    rating: number;
    sentences: {
        feedback: string;
        type: string;
    }
}

export interface IDetailedFeedback {
    interviewId: string;
    userId: string;
    roundNumber: string;
    questionNumber: string;
    question: string;
    userTextResponse: string;
    assessmentFeedback: IAssessmentFeedback;
    deleted: string;
    createdAt: string;
    updatedAt: string;
    attemptNumber: string;
    id: string;
    jobRole?: string;
    overAllRatingDetails?: any;
    rating: number;
    propertyDetails: any;
}

export class InterviewAttemptModel {
    @JsonProperty({ name: 'interviewId', required: true })
    public interviewId: string = "";

    @JsonProperty({ name: 'userId', required: true })
    public userId: string = "";

    @JsonProperty({ name: 'roundNumber', required: true })
    public roundNumber: string = ""

    @JsonProperty({ name: 'questionNumber', required: true })
    public questionNumber: string = ''

    @JsonProperty({ name: 'question', required: true })
    public question: string = "";

    @JsonProperty({ name: 'userTextResponse', required: true })
    public userTextResponse: string = "";

    @JsonProperty({ name: 'assessmentFeedback', required: true })
    public assessmentFeedback: IAssessmentFeedback = null;

    @JsonProperty({ name: 'deleted', required: true })
    public deleted: string = '';

    @JsonProperty({ name: 'createdAt', required: true })
    public createdAt: string = ''

    @JsonProperty({ name: 'updatedAt', required: true })
    public updatedAt: string = ''


    @JsonProperty({ name: 'attemptNumber', required: true })
    public attemptNumber: number = 1;

    @JsonProperty({ name: 'id', required: true })
    public id: string = "";

    @JsonProperty({ name: 'jobRole', required: false })
    public jobRole: string = "";

    public getInterviewId(): string {
        return this.interviewId;
    }

    public getUserId(): string {
        return this.userId;
    }

    public getRoundNumber(): string {
        return this.roundNumber;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }


    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getQuestionNumber(): string {
        return this.questionNumber;
    }

    public getQuestion(): string {
        return this.question;
    }

    public getUserTextResponse(): string {
        return this.userTextResponse;
    }

    public getAssessmentFeedback(): IAssessmentFeedback {
        return this.assessmentFeedback;
    }

    public getDeleted(): string {
        return this.deleted;
    }

    public getId(): string {
        return this.id;
    }

    public getJobRole(): string {
        return this.jobRole;
    }

    public getAttemptNumber(): number {
        return this.attemptNumber;
    }

}
