import { InterviewQuestionsModel } from "@common/domain/models/InterviewQuestionsModel";
import { interviewCoachActions, ISubmitJobRoleValidationResponse, submitInterviewAttempt } from "./actions";
import { InterviewAttemptModel } from "@common/domain/models/InterviewAttemptModel";

export interface IInterviewCoachState {
    submitJobRoleValidation: {},
    getInterviewQuestions: {},
    submitInterviewAttempt: {},
    getInterviewAttempt: InterviewAttemptModel[],
    questionsById: {},
    getAllInterviewAttempts: InterviewQuestionsModel[],
    deleteInterviewAttempt: boolean,
    getInterviewHistory: [],
    loaders: {
        submitJobRoleValidation: boolean;
        getInterviewQuestions: boolean;
        submitInterviewAttempt: boolean;
        getInterviewAttempt: boolean;
        getAllInterviewAttempts: boolean;
        deleteInterviewAttempt: boolean;
        getInterviewHistory: boolean;
    };
    errors: {
        submitJobRoleValidation: string;
        getInterviewQuestions: string;
        submitInterviewAttempt: string;
        getInterviewAttempt: string;
        getAllInterviewAttempts: string;
        deleteInterviewAttempt: string;
        getInterviewHistory: string;
    };
}

export const initialState: IInterviewCoachState = {
    submitJobRoleValidation: {} as ISubmitJobRoleValidationResponse,
    getInterviewQuestions: {} as InterviewQuestionsModel,
    submitInterviewAttempt: {} as InterviewAttemptModel,
    getInterviewAttempt: [],
    questionsById: {},
    getAllInterviewAttempts: [],
    deleteInterviewAttempt: false,
    getInterviewHistory: [],
    loaders: {
        submitJobRoleValidation: false,
        getInterviewQuestions: false,
        submitInterviewAttempt: false,
        getInterviewAttempt: false,
        getAllInterviewAttempts: false,
        deleteInterviewAttempt: false,
        getInterviewHistory: false,
    },
    errors: {
        submitJobRoleValidation: "",
        getInterviewQuestions: "",
        submitInterviewAttempt: "",
        getInterviewAttempt: "",
        getAllInterviewAttempts: "",
        deleteInterviewAttempt: "",
        getInterviewHistory: ""
    },
};

export const interviewCoachReducer = (
    state: IInterviewCoachState = initialState,
    action: { type: string; payload: any }
) => {
    switch (action.type) {

        case interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION:
            return {
                ...state,
                loaders: { ...state.loaders, submitJobRoleValidation: true },
                errors: { ...state.errors, submitJobRoleValidation: "" }
            };

        case interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION_SUCCESS:
            return {
                ...state,
                submitJobRoleValidation: action.payload,
                loaders: { ...state.loaders, submitJobRoleValidation: false }
            };
        case interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, submitJobRoleValidation: false },
                errors: { ...state.errors, submitJobRoleValidation: action.payload }
            };
        case interviewCoachActions.GET_INTERVIEW_QUESTIONS:
        case interviewCoachActions.GET_INTERVIEW_QUESTIONS_BY_ID:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewQuestions: true },
                errors: { ...state.errors, getInterviewQuestions: "" }
            };

        case interviewCoachActions.GET_INTERVIEW_QUESTIONS_SUCCESS:
            updateQuestionsMap([action.payload], state.questionsById);
            return {
                ...state,
                getInterviewQuestions: action.payload,
                loaders: { ...state.loaders, getInterviewQuestions: false }
            };
        case interviewCoachActions.GET_INTERVIEW_QUESTIONS_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewQuestions: false },
                errors: { ...state.errors, getInterviewQuestions: action.payload }
            };
        case interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT:
            return {
                ...state,
                loaders: { ...state.loaders, submitInterviewAttempt: true },
                errors: { ...state.errors, submitInterviewAttempt: "" }
            };

        case interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT_SUCCESS:
            return {
                ...state,
                submitInterviewAttempt: action.payload,
                loaders: { ...state.loaders, submitInterviewAttempt: false }
            };
        case interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, submitInterviewAttempt: false },
                errors: { ...state.errors, submitInterviewAttempt: action.payload }
            };
        case interviewCoachActions.GET_INTERVIEW_ATTEMPT:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewAttempt: true },
                errors: { ...state.errors, getInterviewAttempt: "" }
            };

        case interviewCoachActions.GET_INTERVIEW_ATTEMPT_SUCCESS:
            return {
                ...state,
                getInterviewAttempt: action.payload,
                loaders: { ...state.loaders, getInterviewAttempt: false }
            };
        case interviewCoachActions.GET_INTERVIEW_ATTEMPT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewAttempt: false },
                errors: { ...state.errors, getInterviewAttempt: action.payload }
            };

        case interviewCoachActions.DELETE_INTERVIEW_ATTEMPT:
            return {
                ...state,
                loaders: { ...state.loaders, deleteInterviewAttempt: true },
                errors: { ...state.errors, deleteInterviewAttempt: "" }
            };

        case interviewCoachActions.DELETE_INTERVIEW_ATTEMPT_SUCCESS:
            return {
                ...state,
                deleteInterviewAttempt: action.payload,
                loaders: { ...state.loaders, deleteInterviewAttempt: false }
            };
        case interviewCoachActions.DELETE_INTERVIEW_ATTEMPT_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, deleteInterviewAttempt: false },
                errors: { ...state.errors, deleteInterviewAttempt: action.payload }
            };

        case interviewCoachActions.GET_INTERVIEW_HISTORY:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewHistory: true },
                errors: { ...state.errors, getInterviewHistory: "" }
            };

        case interviewCoachActions.GET_INTERVIEW_HISTORY_SUCCESS:
            return {
                ...state,
                getInterviewHistory: action.payload,
                loaders: { ...state.loaders, getInterviewHistory: false }
            };
        case interviewCoachActions.GET_INTERVIEW_HISTORY_FAIL:
            return {
                ...state,
                loaders: { ...state.loaders, getInterviewHistory: false },
                errors: { ...state.errors, getInterviewHistory: action.payload }
            };

        default:
            return state;
    }
};

export const updateQuestionsMap = (questions, existingMap) => {
    questions.forEach((question) => {
        existingMap[question?.id] = question
    });
};


