import { IState } from "@store/webInterfaces";

export const jobRoleValidationSelector = (state: IState) => state?.interviewCoach?.submitJobRoleValidation || {};
export const jobRoleValidationLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.submitJobRoleValidation || false;
export const jobRoleValidationErrorSelector = (state: IState) => state?.interviewCoach?.errors?.submitJobRoleValidation || "";

export const getInterviewQuestionsSelector = (state: IState) => state?.interviewCoach?.getInterviewQuestions || {};
export const getInterviewQuestionsByIdSelector = (state: IState) => state?.interviewCoach?.questionsById || {};
export const getInterviewQuestionsLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.getInterviewQuestions || false;
export const getInterviewQuestionsErrorSelector = (state: IState) => state?.interviewCoach?.errors?.getInterviewQuestions || '';

export const submitAttemptSelector = (state: IState) => state?.interviewCoach?.submitInterviewAttempt || {};
export const submitAttemptLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.submitInterviewAttempt || false;

export const getInterviewAttemptSelector = (state: IState) => state?.interviewCoach?.getInterviewAttempt || [];
export const getInterviewAttemptLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.getInterviewAttempt || false;
export const getInterviewAttemptErrorSelector = (state: IState) => state?.interviewCoach?.errors?.getInterviewAttempt || '';

export const deleteInterviewAttemptLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.deleteInterviewAttempt || false;

export const getInterviewHistorySelector = (state: IState) => state?.interviewCoach?.getInterviewHistory || [];
export const getInterviewHistoryLoaderSelector = (state: IState) => state?.interviewCoach?.loaders?.getInterviewHistory || false;
export const getInterviewHistoryErrorSelector = (state: IState) => state?.interviewCoach?.errors?.getInterviewHistory || '';