import { InterviewAttemptModel } from "@common/domain/models/InterviewAttemptModel";

const actionTypePrefix = "INTERVIEW_COACH/";

export const interviewCoachActions = {
    SUBMIT_JOB_ROLE_VALIDATION: `${actionTypePrefix}SUBMIT_JOB_ROLE_VALIDATION`,
    SUBMIT_JOB_ROLE_VALIDATION_SUCCESS: `${actionTypePrefix}SUBMIT_JOB_ROLE_VALIDATION_SUCCESS`,
    SUBMIT_JOB_ROLE_VALIDATION_FAIL: `${actionTypePrefix}SUBMIT_JOB_ROLE_VALIDATION_FAIL`,
    GET_INTERVIEW_QUESTIONS: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS`,
    GET_INTERVIEW_QUESTIONS_SUCCESS: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS_SUCCESS`,
    GET_INTERVIEW_QUESTIONS_FAIL: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS_FAIL`,
    SUBMIT_INTERVIEW_ATTEMPT: `${actionTypePrefix}SUBMIT_INTERVIEW_ATTEMPT`,
    SUBMIT_INTERVIEW_ATTEMPT_SUCCESS: `${actionTypePrefix}SUBMIT_INTERVIEW_ATTEMPT_SUCCESS`,
    SUBMIT_INTERVIEW_ATTEMPT_FAIL: `${actionTypePrefix}SUBMIT_INTERVIEW_ATTEMPT_FAIL`,
    GET_INTERVIEW_ATTEMPT: `${actionTypePrefix}GET_INTERVIEW_ATTEMPT`,
    GET_INTERVIEW_ATTEMPT_SUCCESS: `${actionTypePrefix}GET_INTERVIEW_ATTEMPT_SUCCESS`,
    GET_INTERVIEW_ATTEMPT_FAIL: `${actionTypePrefix}GET_INTERVIEW_ATTEMPT_FAIL`,
    GET_INTERVIEW_QUESTIONS_BY_ID: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS_BY_ID`,
    GET_INTERVIEW_QUESTIONS_BY_ID_SUCCESS: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS_BY_ID_SUCCESS`,
    GET_INTERVIEW_QUESTIONS_BY_ID_FAIL: `${actionTypePrefix}GET_INTERVIEW_QUESTIONS_BY_ID_FAIL`,
    DELETE_INTERVIEW_ATTEMPT: `${actionTypePrefix}DELETE_INTERVIEW_ATTEMPT`,
    DELETE_INTERVIEW_ATTEMPT_SUCCESS: `${actionTypePrefix}DELETE_INTERVIEW_ATTEMPT_SUCCESS`,
    DELETE_INTERVIEW_ATTEMPT_FAIL: `${actionTypePrefix}DELETE_INTERVIEW_ATTEMPT_FAIL`,
    GET_INTERVIEW_HISTORY: `${actionTypePrefix}GET_INTERVIEW_HISTORY`,
    GET_INTERVIEW_HISTORY_SUCCESS: `${actionTypePrefix}GET_INTERVIEW_HISTORY_SUCCESS`,
    GET_INTERVIEW_HISTORY_FAIL: `${actionTypePrefix}GET_INTERVIEW_HISTORY_FAIL`,
};

export interface ISubmitJobRoleValidationResponse {
    final_title: string;
    user_title: string;
    message: string;
    jobDescription: string;
    end: boolean;
};

export interface IHistory {
    role: string;
    message: string;
    end?: boolean;
};

export interface IInterviewCoachContext {
    usersChatHistory: IHistory[];
    language: string;
    learnerCountry: string;
};

export interface ISubmitJobRoleValidationPayload {
    key: string;
    context: IInterviewCoachContext;
};

export interface ISubmitInterviewAttemptPayload {
    interviewId: string;
    userId: string;
    question: string;
    userTextResponse: string;
    roundNumber: string;
    questionNumber: string;
};

export interface IDeleteInterviewAttemptPayload {
    id: string;
};

export interface IInterviewHistoryPayload {
    status?: string;
    jobRole?: string
}

export interface ISubmitJobRoleValidationAction {
    type: string;
    payload: ISubmitJobRoleValidationPayload;
}

export interface ISubmitInterviewAttemptAction {
    type: string;
    payload: ISubmitInterviewAttemptPayload;
}

export interface IDeleteInterviewAttemptAction {
    type: string;
    payload: IDeleteInterviewAttemptPayload;
}


export interface IGetInterviewQuestionsContext {
    language: string;
    learnersJobRole: string;
    learnerCountry: string;
}

export interface IGetInterviewQuestionsPayload {
    key: string;
    context: IGetInterviewQuestionsContext;
};

export interface IGetInterviewQuestionsByIdPayload {
    interviewId: string;
    language?: string;
    recentAttempt?: boolean;
    overAllResponse?: boolean;
};


export interface IGetInterviewAttemptPayload {
    interviewId?: string;
    roundNumber?: string;
    questionNumber?: string;
    ratingOrder?: string;
    recent?: boolean;
};

export interface IGetInterviewQuestionsAction {
    type: string;
    payload: IGetInterviewQuestionsPayload;
};

export interface IGetInterviewQuestionsByIdAction {
    type: string;
    payload: IGetInterviewQuestionsByIdPayload;
};

export interface IGetInterviewAttemptAction {
    type: string;
    payload: IGetInterviewAttemptPayload;
};

export const submitJobRoleValidation = (
    payload: ISubmitJobRoleValidationPayload
): ISubmitJobRoleValidationAction => {
    return {
        type: interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION,
        payload
    };
};

export const submitJobRoleValidationSuccess = (response: ISubmitJobRoleValidationResponse) => {
    return {
        type: interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION_SUCCESS,
        payload: response,
    };
};

export const submitJobRoleValidationFailure = (error: string) => {
    return {
        type: interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION_FAIL,
        payload: error,
    };
};

export const getInterviewQuestions = (
    payload: IGetInterviewQuestionsPayload
): IGetInterviewQuestionsAction => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_QUESTIONS,
        payload
    };
};

export const getInterviewQuestionsSuccess = (response: ISubmitJobRoleValidationResponse) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_QUESTIONS_SUCCESS,
        payload: response,
    };
};

export const getInterviewQuestionsFailure = (error: string) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_QUESTIONS_FAIL,
        payload: error,
    };
};

export const submitInterviewAttempt = (
    payload: ISubmitInterviewAttemptPayload
): ISubmitInterviewAttemptAction => {
    return {
        type: interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT,
        payload
    };
};

export const submitInterviewAttemptSuccess = (response: InterviewAttemptModel) => {
    return {
        type: interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT_SUCCESS,
        payload: response,
    };
};

export const submitInterviewAttemptFailure = (error: string) => {
    return {
        type: interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT_FAIL,
        payload: error,
    };
};

export const getInterviewAttempt = (
    payload: IGetInterviewAttemptPayload
): IGetInterviewAttemptAction => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_ATTEMPT,
        payload
    };
};

export const getInterviewAttemptSuccess = (response: ISubmitJobRoleValidationResponse[]) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_ATTEMPT_SUCCESS,
        payload: response,
    };
};

export const getInterviewAttemptFailure = (error: string) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_ATTEMPT_FAIL,
        payload: error,
    };
};

export const getInterviewQuestionsById = (
    payload: IGetInterviewQuestionsByIdPayload
): IGetInterviewQuestionsByIdAction => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_QUESTIONS_BY_ID,
        payload
    };
};

export const deleteInterviewAttempt = (
    payload: IDeleteInterviewAttemptPayload
): IDeleteInterviewAttemptAction => {
    return {
        type: interviewCoachActions.DELETE_INTERVIEW_ATTEMPT,
        payload
    };
};

export const deleteInterviewAttemptSuccess = (response) => {
    return {
        type: interviewCoachActions.DELETE_INTERVIEW_ATTEMPT_SUCCESS,
        payload: response,
    };
};

export const deleteInterviewAttemptFailure = (error: string) => {
    return {
        type: interviewCoachActions.DELETE_INTERVIEW_ATTEMPT_FAIL,
        payload: error,
    };
};

export const getInterviewHistory = (payload) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_HISTORY,
        payload
    };
};

export const getInterviewHistorySuccess = (response) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_HISTORY_SUCCESS,
        payload: response,
    };
};

export const getInterviewHistoryFailure = (error: string) => {
    return {
        type: interviewCoachActions.GET_INTERVIEW_HISTORY_FAIL,
        payload: error,
    };
};