import { JsonProperty } from "json-object-mapper";
import { string } from "prop-types";

export interface IInterviewQuestionType {
    questionNumber: string;
    questionText: string;
    userTextResponse?: string;
    ratingDetails?: object[];
    overAllRatingDetails?: object;
    ratingValue?: number;
    status?: string;
    isFailedQuestion?: boolean;
}
export interface IInterviewQuestions {
    roundNumber: string;
    difficultyLevel: string;
    questions: IInterviewQuestionType[];
    status?: string;
}

export interface IRoundDetails {
    questionIndex?: number;
    roundIndex?: number;
    roundDetails?: IInterviewQuestions;
    questionDetails?: IInterviewQuestionType;
}

export class InterviewQuestionsModel {
    @JsonProperty({ name: 'jobRole', required: true })
    public jobRole: string = "";

    @JsonProperty({ name: 'language', required: true })
    public language: string = "";

    @JsonProperty({ name: 'createdAt', required: true })
    public createdAt: string = ""

    @JsonProperty({ name: 'updatedAt', required: true })
    public updatedAt: string = ''

     @JsonProperty({ name: 'id', required: true })
    public id: string = ''

    @JsonProperty({ name: 'interview', required: true })
    public interview: IInterviewQuestions[] = [];

    @JsonProperty({ name: 'roundDetails', required: false })
    public roundDetails: IRoundDetails = null;

    @JsonProperty({ name: 'generationStatus', required: true })
    public generationStatus: string = '';

    public getJobRole(): string {
        return this.jobRole;
    }

    public getLanguage(): string {
        return this.language;
    }

    public getCreatedAt(): string {
        return this.createdAt;
    }

    public getUpdatedAt(): string {
        return this.updatedAt;
    }

    public getId(): string {
        return this.id;
    }

    public getInterviewQuestions(): IInterviewQuestions[] {
        return this.interview;
    }

    public getRoundDetails(): IRoundDetails {
        return this.roundDetails;
    }

    public getGenerationStatus(): string {
        return this.generationStatus;
    }

}
