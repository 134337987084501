import { call, ForkEffect, put, takeEvery, takeLatest } from 'redux-saga/effects';

import { getErrorCodes } from '@utils/ErrorMessageUtils';
import { StorageKeys } from '@utils/LocalStorage';

import { IMapUserInvitationPayload, IProfile, IUserEducationQualification, ProfileModel } from '@common/domain/models/Profile';
import { ProfileRepository } from '@common/domain/repositories/ProfileRepository';

import { setAuthenticatedFlag } from '@redux/auth/actions';

import { IFluxStandardAction } from '@store/webInterfaces';
import {
    createProfileFail,
    createProfileSuccess,
    fetchDepartmentsFailure,
    fetchDepartmentsSuccess,
    fetchEducationQualificationsFail,
    fetchEducationQualificationsSuccess,
    fetchProfileByEmailId,
    fetchProfileByEmailIdFailure,
    fetchProfileByEmailIdSuccess,
    fetchProfileDetailsFail,
    fetchProfileDetailsSuccess,
    fetchProfilesByUserIdsFailure,
    fetchProfilesByUserIdsSuccess,
    IFetchProfileDetailsAction,
    IFetchProfilesByUserIdsAction, IUpdateProfileDetailsAction,
    mapUserInvitation,
    mapUserInvitationFail,
    mapUserInvitationSuccess,
    getProductMetaDataSuccess,
    getProductMetaDataFail,
    IDeleteUserAction, deleteUserFail,
    deleteUserSuccess,
    profileActions, updateProfileDetailsFail, updateProfileDetailsSuccess,
} from './actions';
import { removeUserData } from '@redux/auth/sagas';
import { IUpdateSettingPayload } from '@common/domain/models/SettingsConfig';
import { updateSettingsSaga } from '@redux/settings/sagas';
import { updateUserSettings } from '@redux/settings/actions';

export function* fetchProfilesByUserIdsSaga(
    action: IFetchProfilesByUserIdsAction
): any {
    try {
        const { userIds } = action.payload;
        const max_size = 25;
        const userIdsArrays = [];
        const profilesArray = [];

        for (let i = 0; i < userIds.length; i += max_size) {
            userIdsArrays.push(userIds.slice(i, i + max_size));
        }

        for (let j = 0; j < userIdsArrays.length; j++) {
            const profiles = yield call(
                ProfileRepository.fetchProfilesByUserIds,
                userIdsArrays[j]
            );
            profilesArray.push(...profiles);
        }
        yield put(fetchProfilesByUserIdsSuccess(profilesArray));
    } catch (error) {
        yield put(fetchProfilesByUserIdsFailure(error.message));
    }
}

export function* fetchProfileUserDetailsSaga(action: IFetchProfileDetailsAction): any { // TODO: Figure out return type.
    try {
        const { userId } = action.payload;
        const response = yield call(ProfileRepository.fetchProfilesByUserIds, [userId]);
        const profiles: ProfileModel = response[0];
        yield put(fetchProfileDetailsSuccess(profiles));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchProfileDetailsFail(error));
    }
}

export function* createProfileSaga(action: any): any {
    try {
        const response = yield call(ProfileRepository.createProfile, action.payload);
        yield put(setAuthenticatedFlag(true));
        const payload: IMapUserInvitationPayload = {
            userEmail: action.payload.email,
            userId: response?.profile?.userId
        }
        const whatsappNotificationPayload: IUpdateSettingPayload = {
            isCreateSettingData:true,
            settingsData: {
                userId: response?.profile?.userId,
                notificationSettings: {
                    whatsAppOptIn: {
                        general: action?.payload?.allowWhatsappNotification || false
                    }
                }
            }
        }
        yield call(updateSettingsSaga,updateUserSettings(whatsappNotificationPayload))
        yield call(mapUserInvitationSaga, mapUserInvitation(payload));
        localStorage.removeItem(StorageKeys.SOCIAL_MEDIA_USER_INFO);
        yield put(createProfileSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(createProfileFail(error));
    }
}

export function* updateProfileSaga(action: IUpdateProfileDetailsAction): any {
    try {
        const profile:ProfileModel = yield call(ProfileRepository.putProfile, action.payload);
        localStorage.setItem(StorageKeys.PREFERRED_LANGUAGE, profile?.getPreferredLanguage());
        yield put(updateProfileDetailsSuccess(profile));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(updateProfileDetailsFail(error));
    }
}

export function* fetchEducationQualificationSaga(action: IFluxStandardAction): any {
    try {
        const response: IUserEducationQualification[] = yield call(ProfileRepository.fetchEducationQualifications);
        yield put(fetchEducationQualificationsSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchEducationQualificationsFail(error));
    }
}

export function* fetchProfileFromEmailIdSaga(action) {
    try {
        const { emailId } = action.payload;
        const response = yield call(ProfileRepository.fetchProfileFromEmailId, emailId);
        yield put(fetchProfileByEmailIdSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchProfileByEmailIdFailure(error));
    }
}

export function* fetchDepartmentsSaga(action) {
    try {
        const response = yield call(ProfileRepository.fetchDepartments);
        yield put(fetchDepartmentsSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(fetchDepartmentsFailure(error));
    }
}

export function* mapUserInvitationSaga(action: IFluxStandardAction<IMapUserInvitationPayload>){
    try {
        const response = yield call(ProfileRepository.mapUserInvitations, action.payload);
        yield put(mapUserInvitationSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(mapUserInvitationFail(error)
        )
    }
}

export function* fetchProductMetaData(action) {
    try {
        const response = yield call(ProfileRepository.profileService, action.payload);
        yield put(getProductMetaDataSuccess({ response, keys: action.payload.keys }));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getProductMetaDataFail(error))
    }
}

export function* deleteUserSaga(action: IDeleteUserAction): any {
    try {
        yield call(ProfileRepository.deleteUser, action.payload);
        yield call(removeUserData);
        yield put(deleteUserSuccess());
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(deleteUserFail(error));
    }
}

export function* watchProfiles(): Generator<ForkEffect> {
    yield takeLatest(profileActions.FETCH_PROFILES_BY_USER_IDS, fetchProfilesByUserIdsSaga);
    yield takeLatest(profileActions.FETCH_PROFILE_DETAILS, fetchProfileUserDetailsSaga);
    yield takeLatest(profileActions.CREATE_PROFILE, createProfileSaga);
    yield takeEvery(profileActions.UPDATE_PROFILE_DETAILS, updateProfileSaga);
    yield takeLatest(profileActions.FETCH_EDUCATION_QUALIFICATION, fetchEducationQualificationSaga);
    yield takeLatest(profileActions.FETCH_PROFILES_BY_EMAIL_ID, fetchProfileFromEmailIdSaga);
    yield takeLatest(profileActions.FETCH_DEPARTMENTS, fetchDepartmentsSaga);
    yield takeLatest(profileActions.MAP_USER_INVITATION, mapUserInvitationSaga);
    yield takeEvery(profileActions.FETCH_PRODUCT_METADATA_START, fetchProductMetaData);
    yield takeLatest(profileActions.DELETE_USER, deleteUserSaga);
}
