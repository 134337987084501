import { call, ForkEffect, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { getErrorCodes } from '@utils/ErrorMessageUtils';

import { InterviewCoachRepository } from '@common/domain/repositories/InterviewCoachRepository';

import { IFluxStandardAction } from '@store/webInterfaces';
import { deleteInterviewAttemptFailure, deleteInterviewAttemptSuccess, getInterviewAttemptFailure, getInterviewAttemptSuccess, getInterviewHistoryFailure, getInterviewHistorySuccess, getInterviewQuestionsFailure, getInterviewQuestionsSuccess, IGetInterviewAttemptPayload, interviewCoachActions, submitInterviewAttemptFailure, submitInterviewAttemptSuccess, submitJobRoleValidationFailure, submitJobRoleValidationSuccess } from './actions';
import { getInterviewQuestionsWithAttempts } from '@presentation/InterviewCoach/utils';
import { getInterviewQuestionsSelector } from './selectors';
import { deepClone } from '@utils/UserUtils';

export function* submitJobRoleValidationSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.submitJobRoleValidation, action.payload);
        yield put(submitJobRoleValidationSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(submitJobRoleValidationFailure(error));
    }
}

export function* getInterviewQuestionsSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.getInterviewQuestions, action.payload);
        const payload: IGetInterviewAttemptPayload = {
            interviewId: response?.getId()
          }
        const attemptsResponse = yield call(InterviewCoachRepository.getInterviewAttempt, payload);
        const { rounds, roundDetails } = getInterviewQuestionsWithAttempts(response, attemptsResponse);
        response.interview = rounds;
        response.roundDetails = roundDetails;
        yield put(getInterviewQuestionsSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getInterviewQuestionsFailure(error));
    }
}

export function* submitInterviewAttemptSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.submitInterviewAttempt, action.payload);
        const questions = yield select(getInterviewQuestionsSelector);
        const previousData = deepClone(questions);
        previousData?.interview?.forEach(eachInterview => {
            if(eachInterview.roundNumber === response?.roundNumber) {
                eachInterview.questions.forEach(eachQuestion => {
                    if(eachQuestion?.questionNumber === response?.questionNumber) {
                        eachQuestion.status = response?.assessmentFeedback?.status;
                    }
                });
            }
        });   
        yield put(getInterviewQuestionsSuccess(previousData));
        yield put(submitInterviewAttemptSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(submitInterviewAttemptFailure(error));
    }
}

export function* getInterviewAttemptSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.getInterviewAttempt, action.payload);
        yield put(getInterviewAttemptSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getInterviewAttemptFailure(error));
    }
}

export function* deleteInterviewAttemptSaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.delteInterviewAttempt, action.payload);
        yield put(deleteInterviewAttemptSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(deleteInterviewAttemptFailure(error));
    }
}

export function* getInterviewQuestionsIdSaga(action: IFluxStandardAction) {
    try {
        const { recentAttempt, ratingOrder, overAllResponse }: any = action.payload
        const response = yield call(InterviewCoachRepository.getInterviewQuestionsById, action.payload);
        const payload: IGetInterviewAttemptPayload = {
            interviewId: response?.getId(),
            ...(ratingOrder && {ratingOrder})
          }
        const attemptsResponse = yield call(InterviewCoachRepository.getInterviewAttempt, payload);
        const { rounds, roundDetails } = getInterviewQuestionsWithAttempts(response, attemptsResponse, recentAttempt, overAllResponse);
        response.interview = rounds;
        response.roundDetails = roundDetails;
        yield put(getInterviewQuestionsSuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getInterviewQuestionsFailure(error));
    }
}

export function* getInterviewHistorySaga(action: IFluxStandardAction) {
    try {
        const response = yield call(InterviewCoachRepository.getInterviewHistory, action.payload);
        yield put(getInterviewHistorySuccess(response));
    } catch (e) {
        const error = getErrorCodes(e);
        yield put(getInterviewHistoryFailure(error));
    }
}

export function* watchInterviewCoach(): Generator<ForkEffect> {
    yield takeLatest(interviewCoachActions.SUBMIT_JOB_ROLE_VALIDATION, submitJobRoleValidationSaga);
    yield takeLatest(interviewCoachActions.GET_INTERVIEW_QUESTIONS, getInterviewQuestionsSaga);
    yield takeLatest(interviewCoachActions.SUBMIT_INTERVIEW_ATTEMPT, submitInterviewAttemptSaga);
    yield takeLatest(interviewCoachActions.GET_INTERVIEW_ATTEMPT, getInterviewAttemptSaga);
    yield takeLatest(interviewCoachActions.GET_INTERVIEW_QUESTIONS_BY_ID, getInterviewQuestionsIdSaga);
    yield takeLatest(interviewCoachActions.DELETE_INTERVIEW_ATTEMPT, deleteInterviewAttemptSaga);
    yield takeLatest(interviewCoachActions.GET_INTERVIEW_HISTORY, getInterviewHistorySaga);
}
