import { JsonProperty } from "json-object-mapper";

export class InterviewHistoryModel {
    @JsonProperty({ name: 'attemptedQuestions', required: true })
    public attemptedQuestions: string[] = [];

    @JsonProperty({ name: 'createdAt', required: true })
    public createdAt: string = ''

    @JsonProperty({ name: 'deleted', required: true })
    public deleted: string = '';

    @JsonProperty({ name: 'id', required: true })
    public id: string = "";

    @JsonProperty({ name: 'interviewId', required: true })
    public interviewId: string = "";

    @JsonProperty({ name: 'interviewStatus', required: true })
    public interviewStatus: string = "";

    @JsonProperty({ name: 'jobRole', required: true })
    public jobRole: string = "";

    @JsonProperty({ name: 'questionsAttempted', required: true })
    public questionsAttempted: number = 0;

    @JsonProperty({ name: 'totalQuestions', required: true })
    public totalQuestions: number = 0;

    @JsonProperty({ name: 'totalRounds', required: true })
    public totalRounds: number = 0;

    @JsonProperty({ name: 'updatedAt', required: true })
    public updatedAt: string = ''

    @JsonProperty({ name: 'userId', required: true })
    public userId: string = "";

    public getAttemptedQuestions(): string[] {
        return this.attemptedQuestions;
    }
    public getCreatedAt(): string {
        return this.createdAt;
    }
    public getDeleted(): string {
        return this.deleted;
    }
    public getId(): string {
        return this.id;
    }
    public getInterviewId(): string {
        return this.interviewId;
    }
    public getInterviewStatus(): string {
        return this.interviewStatus;
    }
    public getJobRole(): string {
        return this.jobRole;
    }
    public getQuestionsAttempted(): number {
        return this.questionsAttempted;
    }
    public getTotalQuestions(): number {
        return this.totalQuestions;
    }
    public getTotalROunds(): number {
        return this.totalRounds;
    }
    public getUpdatedAt(): string {
        return this.updatedAt;
    }
    public getUserId(): string {
        return this.userId;
    }
}
