import { JsonProperty } from "json-object-mapper";

export interface IIdentifiers {
    mapping: string;
    activityId?: string;
    courseId?: string;
    pitchType?: string;
}

export interface IPitchResponse {
    status: string;
    userId?: string;
    jobRole: string;
    videoRecordingUrl?: string;
    transcript?: string;
    identifiers: IIdentifiers;
    notes?: any;
    feedbackFromAI?: string;
    rating?: string;
    language?: string;
    id?: string;
}


export class PitchTrainerModel {

  @JsonProperty({ name: "status", required: true })
  public status: string = "";

  @JsonProperty({ name: "userId", required: false })
  public userId: string = "";

  @JsonProperty({ name: "jobRole", required: true })
  public jobRole: string = "";

  @JsonProperty({ name: "videoRecordingUrl", required: false })
  public videoRecordingUrl: string = "";

  @JsonProperty({ name: "transcript", required: false })
  public transcript: string = "";

  @JsonProperty({ name: "identifiers", required: false })
  public identifiers: IIdentifiers = {} as IIdentifiers;

  @JsonProperty({ name: "notes", required: false })
  public notes: any = {};

  @JsonProperty({ name: "feedbackFromAI", required: false })
  public feedbackFromAI: any = {};

  @JsonProperty({ name: "rating", required: false })
  public rating: string = "";

  @JsonProperty({ name: "language", required: false })
  public language: string = "";

  @JsonProperty({ name: "id", required: false })
  public id: string = "";

  @JsonProperty({ name: "createdAt", required: false })
  public createdAt: string = "";

  @JsonProperty({ name: "updatedAt", required: false })
  public updatedAt: string = "";

  public getUserId(): string {
    return this.userId;
  }

  public getStatus(): string {
    return this.status;
  }

  public getJobRole(): string {
    return this.jobRole;
  }

  public getVideoRecordingUrl(): string {
    return this.videoRecordingUrl;
  }

  public getFeedbackFromAI(): string {
    return this.feedbackFromAI;
  }

  public getIdentifiers(): IIdentifiers {
    return this.identifiers;
  }

  public getTranscript(): string {
    return this.transcript;
  }

  public getNotes(): any {
    return this.notes;
  }

  public getRating(): string {
    return this.rating;
  }

  public getLanguage(): string {
    return this.language;
  }

  public getId(): string {
    return this.id;
  }

  public getCreatedAt(): string {
    return this.createdAt;
  }

  public getUpdatedAt(): string {
    return this.updatedAt;
  }
}
