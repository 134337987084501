import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import history from '@utils/history';

import {
    authReducer,
    initialState as authInitialState,
} from '@common/redux/auth/reducer';
import {
    chatReducer,
    initialState as chatInitialState,
} from '@common/redux/chat/reducer';
import { commentsReducer, initialState as commentsInitialState } from '@common/redux/comments/reducer';
import {
    commonReducer,
    initialState as commonInitialState,
} from '@common/redux/common/reducer';
import {
    connectionReducer,
    initialState as connectionInitialState,
} from '@common/redux/connections/reducer';
import { contentReducer, initialState as contentInitialState } from '@common/redux/content/reducer';
import {
    courseReducer,
    initialState as courseInitialState,
} from '@common/redux/course/reducer';
import { notificationReducer } from '@common/redux/notifications/reducers';
import {
    initialState as profileInitialState,
    profileReducer,
} from '@common/redux/profile/reducer';
import { initialState as settingsInitialState, settingsReducer } from '@common/redux/settings/reducer';
import {
    initialState as studentReportInitialState,
    studentReportReducer,
} from '@common/redux/studentReport/reducer';
import {
    initialState as uploadInitialState,
    uploadReducer,
} from '@common/redux/upload/reducer';
import { initialState as zoomInitialState, zoomReducer } from '@common/redux/zoom/reducer';

import { authActions } from '@redux/auth/actions';
import {
    batchReducer,
    initialState as batchInitialState,
} from '@redux/batch/reducer';
import {
    aiCoachReducer,
    initialState as aiCoachInitialState,
} from '@redux/course/AICoach/reducer';
import {
    meetingReducer,
    initialState as meetingInitalState,
} from '@common/redux/meeting/reducer';
import {
    eventReducer,
    initialState as eventInitialState,
} from '@common/redux/event/reducer';
import {
    facultyReportReducer,
    initialState as facultyReportInitialState,
} from '@redux/facultyReport/reducer';
import {
    initialState as instituteInitialState,
    instituteReducer,
} from '@redux/institutes/reducer';
import {
    initialState as locationInitialState,
    locationReducer,
} from '@redux/location/reducer';
import {
    initialState as rootInitialState,
    rootReducer,
} from '@redux/root/reducer';
import {
    initialState as skillsScoreInitialState,
    skillsScoreReducer,
} from '@redux/skillsScore/reducer';

import {
    instituteAdminReducer,
    initialState as instituteAdminInitialState,
} from '@redux/instituteAdmin/reducer';

import {
    prepareReducer,
    initialState as prepareInitialState,
} from '@redux/interview/reducer';

import authWhiteList from '@store/transform/auth';
import { connectRouter } from 'connected-react-router';
import { forumReducer, initialState as forumInitialState } from '@common/redux/forum/reducer';
import { mentorReducer, initialState as mentorInitialState } from '@common/redux/mentor/reducer';
import { programReducer, initialState as programInitialState } from '@common/redux/program/reducer';
import { pitchTrainerReducer, initialState as pitchTrainerInitialState } from '@common/redux/pitchTrainer/reducer';
import { interviewCoachReducer, initialState as interviewCoachInitialState } from '@common/redux/interviewCoach/reducer';
import { slotBookingReducer, initialState as slotBookingInitialState } from '@common/redux/slot-booking/reducer';


export const rootPersistConfig = {
    key: 'root', // key is required
    storage, // storage is now required
    transforms: [authWhiteList],
};

const reducer = persistReducer(
    rootPersistConfig,
    combineReducers({
        router: connectRouter(history),
        batch: batchReducer,
        location: locationReducer,
        profile: profileReducer,
        course: courseReducer,
        studentReport: studentReportReducer,
        institute: instituteReducer,
        upload: uploadReducer,
        auth: authReducer,
        chat: chatReducer,
        connection: connectionReducer,
        meeting: meetingReducer,
        common: commonReducer,
        root: rootReducer,
        facultyReport: facultyReportReducer,
        skillScores: skillsScoreReducer,
        comments: commentsReducer,
        notification: notificationReducer,
        settings: settingsReducer,
        zoom: zoomReducer,
        aiCoach: aiCoachReducer,
        content: contentReducer,
        interview: prepareReducer,
        event: eventReducer,
        instituteAdmin: instituteAdminReducer,
        forums: forumReducer,
        program: programReducer,
        mentor: mentorReducer,
        pitchTrainer: pitchTrainerReducer,
        interviewCoach: interviewCoachReducer,
        slotBooking: slotBookingReducer,
    })
);

const resetToIntialState = (state: any) => ({
    ...state,
    batch: batchInitialState,
    location: locationInitialState,
    profile: profileInitialState,
    course: courseInitialState,
    studentReport: studentReportInitialState,
    institute: instituteInitialState,
    upload: uploadInitialState,
    auth: authInitialState,
    chat: chatInitialState,
    connection: connectionInitialState,
    meeting: meetingInitalState,
    common: commonInitialState,
    root: rootInitialState,
    facultyReport: facultyReportInitialState,
    skillScores: skillsScoreInitialState,
    comments: commentsInitialState,
    settings: settingsInitialState,
    zoom: zoomInitialState,
    aiCoach: aiCoachInitialState,
    content: contentInitialState,
    event: eventInitialState,
    interview: prepareInitialState,
    instituteAdmin: instituteAdminInitialState,
    forums: forumInitialState,
    program: programInitialState,
    mentor: mentorInitialState,
    pitchTrainer: pitchTrainerInitialState,
    interviewCoach: interviewCoachInitialState,
    slotBooking: slotBookingInitialState,
});

// to clear redux after user triggers logout
export default (state: any, action: any) => {
    if (
        action.type === authActions.CLEAR_USER
        || action.type === authActions.LOGOUT_SUCCESS
    ) {
        return resetToIntialState(state);
    }
    return reducer(state, action);
};
