import {
    deserializeJsonObj, deserializeJsonObjCollection,
  } from "@utils/ObjectMapperUtil";
  
  import { APIv1, APIv2, GLOBAL_BASE_URL_COMMON } from "@common/network/constants";
  
  import { StudentCourseActivity } from "@models/StudentCourseActivity";
  import { StudentQuizReportModel } from "@models/StudentQuizReport";
  import { ApiClient } from "@common/network/client";
import { createSlotBooking, updateSlotBooking } from "@common/redux/slot-booking/actions";
import { SlotBookingModel, utcToLocal } from "../models/SlotBookingModel";
import moment from "moment";
import { getTimeZone } from "@utils/DeviceInfoUtils";
  
  const ENDPOINTS = {
    fetchSlotBooking: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/slots`,
    createSlotBooking: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/slots`,
    updateSlotBooking: (userId: string, groupId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/group/${groupId}/slot`,
    availableslots: (userId: string) => `${GLOBAL_BASE_URL_COMMON}${APIv2}/schedulingservice/user/${userId}/availableSlots`,
  };
  
  const localToUTC = (time) => {
    const [hour, minute] = time.split(":")
    const utcTime = moment().startOf('day').add(hour, "hours").add(minute, "minutes").utc().format("HH:mm");
    return utcTime;
  }

  class SlotBookingRepository {
    private apiClient: any;

    constructor(apiClient: any) {
      this.apiClient = apiClient;
    }
  
    public fetchSlotBooking = async (userId: string) => {
      const response = await this.apiClient.get(
        ENDPOINTS.fetchSlotBooking(userId)
      );
      const slotBooking = deserializeJsonObj(
        response,
        SlotBookingModel,
        "fetchSlots"
      );
      return slotBooking;
    };


    public createSlotBooking = async (userId: string, payload: any) => {
      const timezone = getTimeZone();
      payload.availability = payload.availability.map((item: any) => {
        return {
          day: item.day,
          intervals: item.intervals.map((interval: any) => {
            return {
              startTime: interval.startTime,
              endTime: interval.endTime,
            };
          }),
          timeZone: timezone,
        };
      });
      const response = await this.apiClient.post(
        ENDPOINTS.createSlotBooking(userId),
        payload
      );
      const slotBooking = deserializeJsonObj(
        response,
        SlotBookingModel,
        "fetchSlots"
      );
      return slotBooking;
    }

    public updateSlotBooking = async (userId: string, payload: any) => {
      const timezone = getTimeZone();
      payload.availability = payload.availability.map((item: any) => {
        return {
          day: item.day,
          intervals: item.intervals.map((interval: any) => {
            return {
              startTime: interval.startTime,
              endTime: interval.endTime,
            };
          }),
          timeZone: timezone,
        };
      });
      const response = await this.apiClient.patch(
        ENDPOINTS.updateSlotBooking(userId, payload.groupId),
        payload
      );
      const slotBooking = deserializeJsonObj(
        response,
        SlotBookingModel,
        "fetchSlots"
      );
      return slotBooking;
    }

    public fetchAvailableSlots = async (userId: string, date?: string) => {
      let url = ENDPOINTS.availableslots(userId);
      if (date) {
        url = `${url}?date=${date}`;
      }
      const response = await this.apiClient.get(
        url 
      );
      return {
        ...response,
        slots: response.slots.map((slot: any) => {
          return {
            ...slot,
            startTime: slot.start,
            endTime: slot.end,
          };
        }),
      };
    }
  }


  
  const slotBookingRepository = new SlotBookingRepository(ApiClient);
  
  export { slotBookingRepository as SlotBookingRepository };
  