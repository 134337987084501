import { brazilProfile, egyptProfile, indonesiaProfile, interviewCoachPlaceholderImage, journeyCompleted, journeyLocked, journeyUnlocked, mexicoProfile, phillipinesProfile } from "@assets/images";

export const roundStatusImages = {
  completed: journeyCompleted,
  notStarted: journeyLocked,
  inProgress: journeyUnlocked,
  failed: journeyUnlocked,
  fewCompleted: journeyUnlocked
};

export const roundType = {
  completed: 'completed',
  locked: 'locked',
  unlocked: 'unlocked',
  inProgress: 'inProgress',
  notStarted: 'notStarted',
  failed: 'failed',
  fewCompleted: 'fewCompleted'
};

export const generationStatus = {
  completed: 'completed'
}

export const interviewHistoryStatus = {
  IN_PROGRESS: 'inProgress',
  COMPLETED: 'completed'
}

export const difficultyLevelText = {
  easy: 'easy',
  medium: 'medium',
  hard: 'hard'
};

export const aiuserRole = {
  assistant: 'assistant',
  user: 'user'
};

export const questionType = {
  assistantQuestionFirstQuestion: 'assistantQuestionFirstQuestion',
  otherQuestion: 'otherQuestion'
};

export const validationType = {
  jobRoleValidation: 'jobRoleValidation',
  interviewPrepQuestionGeneration: 'interviewPrepQuestionGeneration'
};

export const questionTextStatus = {
  start: 'start',
  continue: 'continue'
};

export const feedbackType = {
  positive: 'positive',
  constructive: 'constructive'
};

export const getInterviewCoachPlaceholderImage = (location) => {
  switch (location) {
    case 'mexico':
      return mexicoProfile;
    case 'brasil':
      return brazilProfile;
    case 'egypt':
      return egyptProfile;
    case 'philippines':
      return phillipinesProfile;
    case 'indonesia':
      return indonesiaProfile;
    case 'india':
      return interviewCoachPlaceholderImage;
    default:
      return interviewCoachPlaceholderImage;
  }
};

export const dummyRounds = [
  { roundNumber: "R1", difficultyLevel: "Easy", questions: [], status: "notStarted" },
  { roundNumber: "R2", difficultyLevel: "Easy", questions: [], status: "notStarted" },
  { roundNumber: "R3", difficultyLevel: "Easy", questions: [], status: "notStarted" },
  { roundNumber: "R4", difficultyLevel: "Medium", questions: [], status: "notStarted" },
  { roundNumber: "R5", difficultyLevel: "Medium", questions: [], status: "notStarted" },
  { roundNumber: "R6", difficultyLevel: "Medium", questions: [], status: "notStarted" },
  { roundNumber: "R7", difficultyLevel: "Hard", questions: [], status: "notStarted" },
  { roundNumber: "R8", difficultyLevel: "Hard", questions: [], status: "notStarted" },
  { roundNumber: "R9", difficultyLevel: "Hard", questions: [], status: "notStarted" },
  { roundNumber: "R10", difficultyLevel: "Hard", questions: [], status: "notStarted" },
];

export const contentColor = {
  Content_Score: "#F2672A",
  Clarity_Score: "#4EECD8",
  Confidence_Score: "#F68721",
  Professionalism_Score: "#AE72D2"
}