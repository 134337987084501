import { deserializeJsonObj, deserializeJsonObjCollection } from '@utils/ObjectMapperUtil';

import { APIv1, APIv2, APIv3 } from "@common/network/constants";
import { IDeleteInterviewAttemptPayload, IGetInterviewAttemptPayload, IGetInterviewQuestionsPayload, IInterviewHistoryPayload, ISubmitInterviewAttemptPayload } from '@common/redux/interviewCoach/actions';
import { ApiClient, wfcommonApiClient } from "@network/client";
import { getLoggedInUserId } from '@utils/UserUtils';
import { InterviewAttemptModel } from '../models/InterviewAttemptModel';
import { InterviewQuestionsModel } from '../models/InterviewQuestionsModel';
import { IPitchResponse } from '../models/PitchTrainer';
import { InterviewHistoryModel } from '../models/InterviewHistoryModel';

const ENDPOINTS = {
    submitJobRoleValidation: () => `${APIv1}/transformerservice/jobRoleValidation`,
    getInterviewQuestions: () => `${APIv3}/transformerservice/interviewQuestions`,
    getInterviewQuestionsById: () => `${APIv2}/transformerservice/interviewQuestions`,
    submitInterviewAttempt: () => `${APIv2}/transformerservice/interviewAttempts`,
    getInterviewAttempt: (userId) => `${APIv2}/transformerservice/user/${userId}/interviewAttempts?page=1&limit=1000`,
    deletInterviewAttempt: (userId, id) => `${APIv2}/transformerservice/user/${userId}/interviewAttempts/${id}`,
    getInterviewHistory: (userId) => `${APIv2}/transformerservice/user/${userId}/interview/history?nonPaginated=true`
};

class InterviewCoachRepository {
    private apiClient: any;
    private commonApiClient: any;

    constructor(apiClient: any, commonApiClient: any) {
        this.apiClient = apiClient;
        this.commonApiClient = commonApiClient;
    }

    public submitJobRoleValidation = async (payload: IPitchResponse) => {
        const response = await this.apiClient.post(ENDPOINTS.submitJobRoleValidation(), payload);
        return response?.socratic_dialogue;
    }

    public getInterviewQuestions = async (payload: IGetInterviewQuestionsPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.getInterviewQuestions(), payload);
        const getInterviewQuestionsResponse = deserializeJsonObj(response, InterviewQuestionsModel, 'getInterviewQuestions');
        return getInterviewQuestionsResponse;
    }

    public getInterviewQuestionsById = async (payload: IGetInterviewQuestionsPayload) => {
        const response = await this.apiClient.get(ENDPOINTS.getInterviewQuestionsById(), payload);
        const getInterviewQuestionsResponse = deserializeJsonObj(response?.questions[0], InterviewQuestionsModel, 'getInterviewQuestionsById');
        return getInterviewQuestionsResponse;
    }

    public submitInterviewAttempt = async (payload: ISubmitInterviewAttemptPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.submitInterviewAttempt(), payload);
        const submitInterviewAttemptResponse = deserializeJsonObj(response, InterviewAttemptModel, 'submitInterviewAttempt');
        return submitInterviewAttemptResponse;
    }

    public getInterviewAttempt = async (payload: IGetInterviewAttemptPayload) => {
        const userId = getLoggedInUserId();
        const response = await this.apiClient.get(ENDPOINTS.getInterviewAttempt(userId), payload);
        const getInterviewAttemptResponse = deserializeJsonObjCollection(response?.attempts, InterviewAttemptModel, 'getInterviewQuestions');
        return getInterviewAttemptResponse;
    }

    public delteInterviewAttempt = async (payload: IDeleteInterviewAttemptPayload) => {
        const userId = getLoggedInUserId();
        const response = await this.apiClient.delete(ENDPOINTS.deletInterviewAttempt(userId, payload?.id));
        return response ? true : false;
    }

    public getInterviewHistory = async (payload: IInterviewHistoryPayload) => {
        const userId = getLoggedInUserId();
        const response = await this.apiClient.get(ENDPOINTS.getInterviewHistory(userId), payload);
        const getHistoryResponse = deserializeJsonObjCollection(response?.interviews, InterviewHistoryModel, 'getInterviewHistory');
        return getHistoryResponse;
    }
}


const interviewCoachRepository = new InterviewCoachRepository(ApiClient, wfcommonApiClient);

export { interviewCoachRepository as InterviewCoachRepository };
