import { initReactI18next } from 'react-i18next';

import { Logger } from '@utils/Logger';

import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from '../presentation/Interview/jsons/en.json';
import es from '../presentation/Interview/jsons/es.json';
import pt from '../presentation/Interview/jsons/pt.json';
import hi from '../presentation/Interview/jsons/hi.json';
import ar from '../presentation/Interview/jsons/ar.json';
import id from '../presentation/Interview/jsons/id.json';

const resources = {
  en: {
    cou: en,
  },
  es: {
    cou: es,
  },
  pt: {
    cou: pt,
  },
  hi: {
    cou: hi,
  },
  ar: {
    cou: ar
  },
  id: {
    cou: id
  }
};

const detectionOptions = {
  // order and from where course language should be detected
  order: ['path', 'subdomain', 'querystring', 'navigator', 'htmlTag'],

  lookupQuerystring: 'lng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

const i18nOptions = {
  compatibilityJSON: 'v3',
  fallbackLng: 'en',
  lng: 'en',
  whitelist: ['en', 'es', 'pt', 'hi', 'ar', 'id'],
  debug: true,
  defaultNS: 'cou',
  contextSeparator: '-',
  pluralSeparator: '-',
  returnObjects: true,
  load: 'languageOnly',
  interpolation: {
    escapeValue: false,
    prefix: '{{',
    suffix: '}}'
  },
  resources,
  detection: detectionOptions,
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: true,
  },
};

class PlacementPrepI18nInstance {
  private i18n: any;

  private async init() {
    Logger.info('[PlacementPrepI18nInstance - init()] Creating i18n new instance');
    this.i18n = i18next.createInstance();
    this.i18n.use(LanguageDetector);
    this.i18n.use(initReactI18next);
    await this.i18n.init(i18nOptions, (err: any, t: any) => {
      if (err) {
        Logger.error(
          '[PlacementPrepI18nInstance - init()]',
          { error: 'unable to init i18n instance. err:' + err },
        );
      }
      Logger.info(
        '[PlacementPrepI18nInstance - init()]',
        { info: 'instance created successfully. Test:' + t('labelsAndTitles.HOME')},
      );
    });
  }

  public getI18n() {
    if (!this.i18n) {
      this.init();
    }
    return this.i18n;
  }
}

const placementPrepI18nSingleton = new PlacementPrepI18nInstance();
const placementPrepAdvantagei18n = placementPrepI18nSingleton.getI18n();
export { placementPrepAdvantagei18n as i18n };
