import { dummyRounds, roundType } from "./Constants";

export const getPropertyDetails = (data) => {
  const result = data?.map((item) => {
    const key = Object.keys(item)[0]; // Extract the key name (relavance, clarity)
    return {
      rating: item[key].rating,
      name: key,
      sentences: item[key].sentences
    };
  });
  return result || [];
};

const getUserResponse = (attempts, recentAttempt, overAllResponse, questionNumber) => {
  let userResponse: any = {};
  let isFailedQuestion = false;
  if (recentAttempt) {
    userResponse = attempts?.find(eachAttempt => eachAttempt?.questionNumber === questionNumber) || {};
    const allUserResponses = attempts?.filter(eachAttempt => eachAttempt?.questionNumber === questionNumber);
    userResponse = allUserResponses.reduce((max, current) => {
      return current.attemptNumber > max.attemptNumber ? current : max;
    }, allUserResponses[0]);
    if (userResponse?.attemptNumber > 1) {
      const findFailedAttempt = allUserResponses.find(eachResponse => eachResponse?.attemptNumber === userResponse?.attemptNumber - 1);
      const ratingDetails = findFailedAttempt?.assessmentFeedback?.feedbacks.find(eachRating => eachRating.overall)?.overall || {};
      if (findFailedAttempt && ratingDetails && Math.floor(parseInt(ratingDetails?.rating)) < 3) {
        isFailedQuestion = true;
      }
    }
  } else {
    if (overAllResponse) {
      const allUserResponses = attempts?.filter(
        eachAttempt => eachAttempt?.questionNumber === questionNumber
      );
      userResponse = allUserResponses.reduce((max, current) => {
        const maxRating = Math.floor(
          parseInt(max?.assessmentFeedback?.feedbacks.find(eachRating => eachRating.overall)?.overall?.rating) || 0
        );
        const currentRating = Math.floor(
          parseInt(current?.assessmentFeedback?.feedbacks.find(eachRating => eachRating.overall)?.overall?.rating) || 0
        );
        return currentRating > maxRating ? current : max;
      }, allUserResponses[0]);
    } else {
      userResponse = attempts?.find(eachAttempt => eachAttempt?.questionNumber === questionNumber) || {};
    }
  }
  return { userResponse, isFailedQuestion };
};

const getRoundMap = (round, index, self, attempts) => {
  if (round?.questions?.length === 0) return round;
  const ratingValues = round?.questions?.map(eachQuestion => eachQuestion?.ratingValue);
  const ratingValuesAboveThree = round?.questions?.every(eachQuestion => eachQuestion?.ratingValue >= 3 && eachQuestion?.status === roundType.completed) || false;
  const ratingValuesBelowThreeCompleted = round?.questions?.every(eachQuestion => eachQuestion?.ratingValue > 0 && eachQuestion?.ratingValue < 3 && eachQuestion?.status === roundType.completed) || false;
  const ratingValuesBelowThreeNotCompleted = round?.questions?.some(eachQuestion => eachQuestion?.ratingValue > 0 && eachQuestion?.ratingValue < 3 && (eachQuestion?.status === roundType.completed || eachQuestion?.status === roundType.notStarted)  ) || false;
  const previousRatingValues = self[index - 1]?.questions?.map(eachQuestion => eachQuestion?.ratingValue);
  if (ratingValuesAboveThree) {
    round.status = roundType.completed;
  } else if (ratingValuesBelowThreeCompleted) {
    round.status = roundType.failed;
  } else if (ratingValuesBelowThreeNotCompleted) {
    round.status = roundType.fewCompleted;
  } else if (!ratingValuesBelowThreeCompleted && (previousRatingValues?.every(value => value >= 3) || (!attempts.length && index === 0))) {
    round.status = roundType.inProgress;
  } else if (ratingValues?.some(value => value > 0)) {
    round.status = roundType.fewCompleted;
  } else {
    round.status = roundType.notStarted;
  }
  
  return round;
};

export const getInterviewQuestionsWithAttempts = (
  interviewQuestions,
  attempts,
  recentAttempt = false,
  overAllResponse = false
) => {

  const roundsMap = dummyRounds.reduce((acc, round) => {
    acc[round.roundNumber] = { ...round, questions: [] };
    return acc;
  }, {});

  interviewQuestions?.getInterviewQuestions()?.forEach((eachInterviewQuestion) => {
    const { roundNumber, questionNumber, questionText } = eachInterviewQuestion;
    let { userResponse, isFailedQuestion } = getUserResponse(
      attempts,
      recentAttempt,
      overAllResponse,
      questionNumber
    );
    const overAllRatingDetails = userResponse?.assessmentFeedback?.feedbacks.find(
      (eachRating) => eachRating.overall
    )?.overall || {};

    const questionData = {
      questionNumber,
      questionText,
      status: userResponse?.assessmentFeedback?.status || roundType.notStarted,
      userTextResponse: userResponse?.userTextResponse || "",
      ratingDetails: getPropertyDetails(
        userResponse?.assessmentFeedback?.feedbacks?.filter((eachRating) => !eachRating.overall)
      ) || [],
      overAllRatingDetails,
      ratingValue: overAllRatingDetails?.rating
        ? Number(parseFloat(overAllRatingDetails?.rating).toFixed(1))
        : 0,
      isFailedQuestion,
    };

    if (!roundsMap[roundNumber].questions) {
      roundsMap[roundNumber].questions = [];
    }
    roundsMap[roundNumber].questions.push(questionData);
  });

  const rounds = Object.values(roundsMap).map((round, index, self) =>
    getRoundMap(round, index, self, attempts)
  );
  return { rounds, roundDetails: getRoundDetails(rounds) };
};


export const getRoundDetails = (rounds = []) => {
  const roundInProgressIndex = rounds?.findIndex(eachRound => eachRound?.status === roundType?.inProgress || eachRound?.status === roundType?.failed) || 0;
  const findEmptyQuestionIndex = rounds[roundInProgressIndex]?.questions?.findIndex(eachQuestion => eachQuestion?.ratingValue === 0) || 0;
  return {
    questionIndex: findEmptyQuestionIndex,
    roundIndex: roundInProgressIndex + 1,
    roundDetails: rounds[roundInProgressIndex],
    questionDetails: rounds[roundInProgressIndex]?.questions[findEmptyQuestionIndex],
  }
};

export const sortByUpdatedAt = (data: any[]) => {
  if(Array.isArray(data)){
    return [...data]?.sort((a, b) =>
      new Date(b?.updatedAt).getTime() - new Date(a?.updatedAt).getTime()
    );
  }
  return data;
};
