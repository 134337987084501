import { LocalStorage, StorageKeys } from "@utils/LocalStorage";

import { APIv1, APIv2 } from "@common/network/constants";
import { IApiClient } from "@common/network/interfaces";
import {
    ICheckUserExist,
    IEmailVerify,
    IForgotPassword,
    ILoginPayload,
    ILogoutModel,
    IResendOtpPayload,
    IResetPassword,
    ISocialMediaLogin,
    ISSOLoginPayload,
    IVerifyUserCredentialsPayload
} from "@common/redux/auth/interface";

import { ApiClient } from "@network/client";
import uuid from 'uuid';


export const ENDPOINTS = {
    login: () => `${APIv1}/iamservice/login`,
    ssologin: () => `${APIv1}/iamservice/loginssouser`,
    checkUserExist: () => `${APIv1}/iamservice/checkUserExist`,
    register: () => `${APIv2}/iamservice/register`,
    resendVerifyMail: () => `${APIv1}/iamservice/resendVerifyLink`,
    forgotPassword: () => `${APIv1}/iamservice/forgotPassword`,
    changePassword: () => `${APIv1}/iamservice/changePassword`,
    registerUser: (id: string) => `${APIv1}/users/${id}/register`,
    unregister: (id: string) => `${APIv1}/users/${id}/register`,
    deleteToken: () => `${APIv1}/token`,
    updatePassword: () => `${APIv1}/iamservice/updatePassword`,
    refreshAuthToken: () => `${APIv1}/iamservice/refreshToken`,
    logoutUser: () => `${APIv1}/iamservice/logout`,
    emailVerification: () => `${APIv1}/iamservice/verify`,
    socialMediaLogin: (type: string) => `${APIv1}/iamservice/socialLogins?type=${type}`,
    fetchNsdcUrl: (nsdcSsoToken) => `${APIv1}/iamservice/nsdc/sso?token=${nsdcSsoToken}`,
    authoriseSsoUser: () => `${APIv1}/iamservice/idp/oauth/authorize`,
    resendOtp: () => `${APIv2}/iamservice/resendTempPassword`,
    verifyUserCredentials: () => `${APIv1}/iamservice/verifyUserCredentials`,
}

class AuthRepository {
    private apiClient: IApiClient;
    constructor(apiClient: IApiClient) {
        this.apiClient = apiClient;
    }

    public login = async (payload: ILoginPayload) => {
        payload.userName = payload.userName.toLowerCase();
        const response = await this.apiClient.post(ENDPOINTS.login(), payload);
        return response;
    }

    public ssoLogin = async (payload: ISSOLoginPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.ssologin(), payload);
        return response;
    };

    public checkUserExist = async (payload: ICheckUserExist) => {
        const response = await this.apiClient.post(ENDPOINTS.checkUserExist(), payload);
        return response;
    }

    public register = async (payload: ILoginPayload) => {
        const { password, userName, fromSuperAdminSignIn, instituteCode, language, termsAndConditionsAcceptance, userData } = payload;
        const loginData = { password, userData, userName, fromSuperAdminSignIn, language, termsAndConditionsAcceptance };
        const params = { instituteCode }
        const response = await this.apiClient.post(ENDPOINTS.register(), loginData, params);
        return response;
    }

    public resendVerifyMail = async (payload: ILoginPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.resendVerifyMail(), payload);
        return response;
    }

    public forgotPassword = async (payload: IForgotPassword): Promise<{}> => {
        const response = await this.apiClient.post(ENDPOINTS.forgotPassword(), payload);
        return response;
    }

    public changePassword = async (payload: ILoginPayload): Promise<{}> => {
        payload.userName = payload.userName.toLowerCase();
        // payload.termsAndConditionsAcceptance = undefined;
        const response = await this.apiClient.post(ENDPOINTS.changePassword(), payload);
        return response;
    }

    public getApiClient = (): IApiClient => {
        return this.apiClient;
    }

    public registerUser = async (id: string, fcmToken: string, platform?: string): Promise<any> => {
        const devicePlatform = platform || '';
        const Uuid = uuid.v1();
        const data = { device: { platform: devicePlatform, token: fcmToken, installationId: Uuid } };
        await LocalStorage.set<string>(StorageKeys.USER_ID, id);
        await LocalStorage.set<string>(StorageKeys.USER_UUID, Uuid);
        const response = await this.apiClient.put(ENDPOINTS.registerUser(id), data);
        return response;
    }

    public logout = async (data: ILogoutModel): Promise<any> => {
        const userId: string = await LocalStorage.get<string>(StorageKeys.USER_ID);
        let response = null;
        if (userId) {
            response = await this.apiClient.delete(ENDPOINTS.unregister(JSON.parse(userId)), data);
        }
        return response;
    }

    public deleteAuthToken = async (): Promise<any> => {
        const response = await this.apiClient.delete(ENDPOINTS.deleteToken());
        return response;
    }

    public updatePassword = async (payload: IResetPassword): Promise<{}> => {
        const response = await this.apiClient.post(ENDPOINTS.updatePassword(), payload);
        return response;
    }

    public refreshToken = async (refreshToken: string): Promise<any> => {
        const response = await this.apiClient.post(ENDPOINTS.refreshAuthToken(), {
            refreshToken: refreshToken
        });
        return {
            accessToken: response.access_token,
            refreshToken: response.refresh_token
        };
    }

    public logoutUser = async (): Promise<{}> => {
        const payload = {}
        const response = await this.apiClient.post(ENDPOINTS.logoutUser(), payload);
        return response;
    }

    public emailVerification = async (payload: IEmailVerify) => {
        const response = await this.apiClient.get(ENDPOINTS.emailVerification(), payload);
        return response;
    }

    public socialMediaLogin = async (payload: ISocialMediaLogin) => {
        const response = await this.apiClient.post(ENDPOINTS.socialMediaLogin(payload.type), { token: payload.token }, { instituteCode: payload.instituteCode });
        return response;
    }

    public getNsdcSsoUrl = async (nsdcSsoToken: string) => {
        const response = await this.apiClient.post(ENDPOINTS.fetchNsdcUrl(nsdcSsoToken));
        return response
      };

      public authoriseSsoUser = async (payload) => {
        const response = await this.apiClient.post(ENDPOINTS.authoriseSsoUser(), { email: payload.email }, payload.data);
        return response;
    }

    public resendOtp = async (payload: IResendOtpPayload) => {
        const response = await this.apiClient.post(ENDPOINTS.resendOtp(), payload);
        return response;
    }  

    public verifyUserCredentials = async (
        payload: IVerifyUserCredentialsPayload,
    ): Promise<{}> => {
        const response = await this.apiClient.post(
            ENDPOINTS.verifyUserCredentials(),
            payload,
        );
        return response;
    };
}

const authRepository = new AuthRepository(ApiClient);

export { authRepository as AuthRepository };
