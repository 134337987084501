import { envType } from '@config/ConfigConstant';
import Config from '@config/index';
import { BrowserTracing } from '@sentry/browser';
import * as Sentry from '@sentry/react';
import packageJson from '../../package.json';

const browserTracingIntegration = {
    name: 'BrowserTracing',
    setupOnce() {
      new BrowserTracing();
    },
  };

export class SentryService {

    private static captureSentryException(
        severity: Sentry.SeverityLevel,
        message: string,
        extraInfo?: any,
      ) {
        let context = {
          level: severity,
          tags: extraInfo,
        };
        Sentry.captureException(new Error(message), context);
      }

    public static async init() {
        try {
            Sentry.init({
                dsn: Config.SENTRY_CONFIG.DSN,
                tracesSampleRate: 1.0,
                ignoreErrors: ['ChunkLoadError', 'ResizeObserver'],
                release: packageJson.version,
                enabled: process.env.REACT_APP_ENV === envType.dev || process.env.REACT_APP_ENV === envType.prod || process.env.REACT_APP_ENV === envType.stage,
                environment: process.env.REACT_APP_ENV,
                integrations: [browserTracingIntegration],
            });
        } catch (error) {
            console.error('Error initializing Sentry:', error);
        }
    }

    public static setUserContextOnLogin(userId: string) {
        Sentry.configureScope(function (scope) {
            scope.setUser({
                id: userId,
            });
        });
    }


    public static removeUserContextOnLogout() {
        Sentry.configureScope(scope => scope.setUser(null));
    }

    public static logInfo(message: any, extraInfo?: any) {
        Sentry.captureMessage(message, extraInfo);
    }
}

